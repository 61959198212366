import React from "react";
import _ from "lodash";
import Image from "../image";
import moment from "moment";
import MobileDetect from "mobile-detect";
import { setParams, getParams, navigateTo } from "../../utils/location";
import AppContainer from "../AppContainer";
import Card from "../Card";
import Loader from "../Loader";
import styles from "./index.module.scss";
import EmptyState from "../EmptyState";
import Button from "../Button";
import localeDates from "../../utils/localeDates";
import { mediaTypes } from "../../utils/media";
import NoImageRenderer from "../no-image-renderer";

export default class ReorderView extends React.Component {
  state = { chosenOrder: null };
  componentDidMount() {
    this.props.loadLatestOrders();
    this.props.loadBranchAvailabiltyIfNeeded();
    this.md = new MobileDetect(window.navigator.userAgent);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.locations.loadingDeliveryOptions &&
      !this.props.locations.loadingDeliveryOptions &&
      !this.props.locations.loadDeliveryOptionsError
    ) {
      this.startReorder(this.state.chosenOrder);
    }
  }

  loadDeliveryAreasIfNeededAndStartReorder = (order) => () => {
    if (order.servingOption.needsAddress) {
      this.setState({ chosenOrder: order });
      if (!order.deliveryAddress) {
        this.startReorder(order);
        navigateTo("/find-location");
      } else {
        const geoPoint = order.deliveryAddress.geoPoint;
        this.props.loadDeliveryAreas({
          lat: geoPoint.latitude,
          lng: geoPoint.longitude,
          servingOptionId: order.servingOption.id,
          deliveryAddress: order.deliveryAddress,
        });
      }
    } else {
      this.startReorder(order);
    }
  };

  startReorder = (order) => {
    const { branchesAvailability } = this.props;
    const branchAvailability = _.get(branchesAvailability, `${order.branchId}`);
    const hasTableCodes = _.get(order, "servingOption.hasTableCodes");
    if (branchAvailability && !hasTableCodes) {
      const firstAvailableDate =
        _.get(branchesAvailability, `${order.branchId}.availableFrom`) ||
        (_.get(branchesAvailability, `${order.branchId}.availability`) ===
          "AVAILABLE_NOW" &&
          new Date());

      this.props.setOrderForReorder(order, firstAvailableDate);

      this.props.openCheckoutView();
      navigateTo(setParams("/order", { ...this.params, fromReorder: true }));
    } else {
      this.props.setOrderForReorder(order);
      const preSelectedBranchId = hasTableCodes && order.branchId;
      navigateTo(
        setParams("/find-location", {
          ...this.params,
          fromReorder: true,
          preSelectedBranchId,
        }),
      );
    }
  };

  render() {
    const {
      appStyles,
      pageContext: { branches, servingOptions },
      location,
      user,
      latestOrders,
      T,
      locations,
    } = this.props;

    const { PageHeader = {} } = appStyles;
    this.params = getParams(location);
    if (user.loadingLatestOrdersError) {
      console.warn("Failed to load latest orders");
    }
    if (locations.loadBranchesAvailabilityError) {
      console.warn("Failed to load branches availabilty");
    }
    if (locations.loadDeliveryOptionsError) {
      console.warn("Failed to load delivery options");
    }
    if (
      user.loadingLatestOrders ||
      locations.loadingBranchesAvailability ||
      locations.loadingDeliveryOptions
    ) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>
              {locations.loadingDeliveryOptions
                ? T("Loading your order...")
                : T("Loading previous orders...")}
            </strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (
      user.loadingLatestOrdersError ||
      locations.loadBranchesAvailabilityError ||
      locations.loadDeliveryOptions
    ) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn style={{ marginTop: "45%" }}>
            <h4 style={{ color: appStyles.accentColor }}>
              {T("Could not load your orders :(")}
            </h4>
            <Button
              appStyles={appStyles}
              onClick={
                user.loadingLatestOrdersError
                  ? this.props.loadLatestOrders
                  : this.props.loadBranchAvailabiltyIfNeeded
              }
            >
              {T("Try Again")}
            </Button>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (_.isEmpty(latestOrders)) {
      return (
        <EmptyState
          T={T}
          appStyles={appStyles}
          description={T("You have no orders yet")}
          shouldNavigateToServingOptions={servingOptions.length < 2}
          onClick={this.props.startNewOrder}
        />
      );
    }

    if (_.isEmpty(branches)) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn style={{ marginTop: "100px" }}>
            <h2 style={{ color: appStyles.accentColor }}>
              {T(`We're closed right now, please check back at another time.`)}
            </h2>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    const branchById = _.keyBy(branches, "id");

    const renderCarusleMenuItem = (item, index) => {
      const { configuredMenuItemOrderData, sourceItem = {} } = item;

      return (
        <div
          key={index}
          style={{
            width: 120,
            height: "auto",
            margin: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {_.get(sourceItem, "imageKey") ? (
            <Image
              mediaKey={sourceItem.imageKey}
              mediaType={
                sourceItem.useCategoryImage
                  ? mediaTypes.menuCategory
                  : mediaTypes.menuItem
              }
              sizes={"90px"}
              imagePreview={sourceItem.imagePreview}
              wrapperStyle={{
                borderRadius: appStyles.cardBorderRadius,
                height: 90,
                width: 90,
              }}
            />
          ) : (
            <NoImageRenderer appStyles={appStyles} />
          )}
          <div
            style={{ marginTop: 6, textAlign: "center", fontSize: "0.8rem" }}
          >
            {configuredMenuItemOrderData.itemName}
          </div>
        </div>
      );
    };

    const renderOrderPreview = (order, index) => (
      <Card
        key={index}
        appStyles={appStyles}
        onClick={this.loadDeliveryAreasIfNeededAndStartReorder(order)}
        classNames={styles.Clickable}
      >
        <Card.Title light small appStyles={appStyles}>
          {T(order.servingOption.servingOptionTag)}
          {` ${T("from")} `}
          {branchById[order.branchId]
            ? branchById[order.branchId].name
            : T("a non available location")}
        </Card.Title>
        <Card.Content>
          {moment(order.statusChangeDate).format(
            `dddd D MMM, YYYY | ${
              localeDates[appStyles.locale || "en-US"].hourFormat
            }`,
          )}
        </Card.Content>
        <Card.Content>
          <div className={styles.ItemCarusel}>
            {_.map(order.orderItems, renderCarusleMenuItem)}
          </div>
        </Card.Content>
      </Card>
    );
    return (
      <AppContainer.Content
        tightBottom
        appStyles={appStyles}
        classNames={styles.DesktopGutters}
      >
        <h2 style={PageHeader}>{T("Your Last Order")}</h2>
        {renderOrderPreview(_.head(latestOrders))}
        {latestOrders.length > 1 && (
          <h2 style={PageHeader}>{T("Previous Orders")}</h2>
        )}
        {_.map(_.tail(latestOrders), renderOrderPreview)}
      </AppContainer.Content>
    );
  }
}
